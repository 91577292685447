<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">观察管理</span>
            <div class="a-flex-rcc">
                <!-- <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button> -->
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="editObserve('add')">添加观察</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <!-- <le-input-date label="日期" v-model="pageParam.params.countDate"></le-input-date> -->
                <le-input label="观察名称" v-model="pageParam.params.name" />
            </le-search-form>
            <le-pagview ref="observeListPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.observeList" :pageSizeDefault='10'>
                <el-table ref="observeList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="province" label="达标值" min-width="140" fixed='left'>
                        <template slot-scope="{ row }">
                            <el-select v-model="row.gradeValueId" :clearable='false' @change="gradeValueChange">
                                <el-option v-for="(item,index) in row.gradeVoList"
                                    :key="index"
                                    :label='"￥" + util.numFormat(item.gradeValue)'
                                    :value="item.id">
                                    <span>￥{{ util.numFormat(item.gradeValue) }}</span>
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationName" label="观察名称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.name || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="观察阈值" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.gradeValue) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="历史单插槽收益" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.hisPerSlotIncome) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="昨日单插槽收益" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.yestPerSlotIncome) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="历史总收益" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.hisAllIncome) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="昨日收益" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.yestAllIncome) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="站点总数" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationNum || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="昨日新增运营站点数" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.yestAddStationNum || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="昨日达标站点数" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.yestPassStationNum || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="昨日达标站点同比" min-width="140">
                        <template slot-scope="{ row }">
                            <div class="a-flex-rfsc">
                                <span>{{ row.yestPassStationNumChange || 0 }}</span>
                                <img v-if="row.yestPassStationNumChange>0" src="../../assets/icon/index-top-up.png" class="a-wh-16" />
                                <img v-if="row.yestPassStationNumChange<0" src="../../assets/icon/index-top-down.png" class="a-wh-16" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="昨日历史达标站点同比" min-width="150">
                        <template slot-scope="{ row }">
                            <div class="a-flex-rfsc">
                                <span>{{ row.hisPassStationNumChange || 0 }}</span>
                                <img v-if="row.hisPassStationNumChange>0" src="../../assets/icon/index-top-up.png" class="a-wh-16" />
                                <img v-if="row.hisPassStationNumChange<0" src="../../assets/icon/index-top-down.png" class="a-wh-16" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="历史达标站点数" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.hisPassStationNum || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="整体达标率" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ (row.passRate*100).toFixed(2) || 0 }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="插槽数(在线/总数)" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.slotOnlineNum || 0 }}/{{ row.slotNum || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="看板分析" placement="top">
                                <img src="../../assets/icon/menu-chart-active.png" class="a-wh-16" @click="toObserveChart(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="站点分析" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16 a-ml-12" @click="toObserveStation(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <img src="../../assets/icon/option-edit.png" class="a-wh-16 a-ml-12" @click="editObserve('add',scope.row)" v-if="userInfo.dataPermission != 77" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import {
        mapState,
        mapActions
    } from 'vuex';
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.getObservePage,
                    method: "post",
                    params: {
                        name: '',
                        countDate: this.$getDay.getTodayBeforeDays(1)
                    },
                    freshCtrl: 1,
                },
                currentGrade: null
            }
        },
        created () {

        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        computed: {
            ...mapState({
                userInfo: state => state.user.user,
            })
        },
        methods:{
            //获取列表
            setTableData(data) {
                this.tableData = data.map(res=>{
                    return {
                        ...res,
                        gradeValueId: res.gradeId
                    }
                });
            },
            handlerRest() {
                this.pageParam.params = {
                    name: '',
                    countDate: this.$getDay.getTodayBeforeDays(1)
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['observeList'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            // 切换达标阈值
            gradeValueChange (e) {
				let gradeVoList = this.tableData.find(res=>{
					return res.gradeValueId == e
				}).gradeVoList

                let grade = gradeVoList.find(res=>{
                    return res.id = e
                })
                this.$Axios._post({
                    url: this.$Config.apiUrl.getObserveDetails,
                    method: "post",
                    showLoading: false,
                    params: {
                        id: grade.observeId,
						gradeId: grade.id,
						countDate: this.pageParam.params.countDate
                    }
                }).then(res => {
                    this.tableData.map((item,index)=>{
                        if(item.id == res.result.data.id){
                            this.$set(this.tableData,index,{
                                ...res.result.data,
                                gradeValueId: res.result.data.gradeId
                            })
                        }
                    })
                })
            },
            editObserve (type, datas) {
                this.$router.push({
                    path: '/observe/observe-edit',
                    query:{
                        gradeId: datas?datas.gradeId:'',
                        observeId: datas?datas.id:'',
                        editType: type
                    }
                })
            },
            toObserveChart (datas) {
                this.$router.push({
                    path: '/observe/observe-chart',
                    query:{
                        gradeId: datas.gradeId,
                        observeId: datas.id,
                        observeName: datas.name
                    }
                })
            },
            toObserveStation (datas) {
                this.$router.push({
                    path: '/observe/observe-station-list',
                    query:{
                        gradeId: datas.gradeId,
                        observeId: datas.id,
                        observeName: datas.name
                    }
                })
            },
            exportfile () {
                // this.$exfile({
                //     code: 15,
                //     fileName: this.pageParam.params.searchStartTime + ' 至 ' + this.pageParam.params.searchEndTime + ' 省份经营分析',
                //     startTime: this.pageParam.params.searchStartTime,
                //     endTime: this.pageParam.params.searchEndTime,
                //     params: this.pageParam.params
                // })
            }
        }
    }
</script>

<style lang="scss" scoped>
.s-btn-add{
    width: 105px;
}
/deep/ .s-search-label{
    width: 100px
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>